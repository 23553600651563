.signet {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    background-color: var(--second-bg-color);
    height: fit-content;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    margin: 15px 10px;
    padding: 5px 10px;
    border: 1px solid var(--accent-color);
}

.signet.viewed{
    border: unset;
}

.signet > img {
    height: 24px;
    width: 24px;
}

.signet > div {
    display: block;
    text-align: left;
}

.signet h3 {
    font-weight: 400;
    font-size: 16px;
}

.signet .text {
    font-style: italic;
    font-weight: 300;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* Number of lines you want to show */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    max-width: 100%;
    color: var(--second-text-color);
    font-size: 10px;
}


@media screen and (min-width: 800px){
    .signet {
        border-radius: 20px;
        margin: 15px 10px;
        padding: 10px 20px;
        border: 2px solid var(--accent-color);
    }

    .signet > img {
        height: 40px;
        width: 40px;
    }

    .signet h3 {
        font-weight: 600;
        font-size: 24px;
    }

    .signet .text {
        font-size: 20px;
    }

}
