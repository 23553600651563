.top {
    height: 80px;
    top: 0;
    display: flex;
    align-items: center;
    z-index: 999;
    background: var(--second-bg-color);
    color: var(--second-text-color);
    justify-content: space-between;
}
.top .link {
    text-transform: uppercase;
    display: inline;
    position: relative;
    overflow: hidden;
    opacity: .9999;
}
.top .link:after {
    top:17px;
    height: 1px;
    content: "";
    position: absolute;
    z-index: -1;
    right: 0;
    width: 0;
    background: var(--second-text-color);
    transition-property: width;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
}
.top .link:hover:after,
.top .link:focus:after,
.top .link:active:after {
    left: 0;
    right: auto;
    width: 100%;
}

.top .topList {
    display: flex;
    justify-content: center;
    margin: 0;
    list-style: none;
    gap: 20px;
}

.top .topListItem {
    font-size: 13px;
    font-weight: bold;
    cursor: pointer;
}

.top .topRight {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 50px;
}

.top .topLeft{
    display: flex;
    align-items: center;
    justify-content: left;
    margin: 0 25px;
}

.top .topLeft img{
    height: 60px;
    width: auto;
    cursor: pointer;
}

@media (min-width: 800px) {

    .top .topListItem{
        font-size: 18px;
    }
    .top .link:after{
        top: 25px;
        height: 2px;
    }

}