.card{
    padding: 0 20px;
    margin: 20px;
    border: 2px solid var(--accent-color);
    border-radius: 10px;
    background: var(--second-bg-color);
    position: relative;
}
.card .header,
.card .footer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 25px 0;
}

.card .header h2{
    margin: 0;
    text-align: left;
}

.card .header img{
    height: 20px;
    width: 20px;
}

.card .text{
    text-align: justify;
}

.card .footer {
    height: 40px;
}

.card .footer span{
    font-style: italic;
    font-size: 8px;
    color: var(--second-text-color);
}

.card img{
    user-select: none;
}
.card .see-more{
    font-weight: bold;
    color: var(--second-text-color);
}

.card .emoji-pill{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    background: var(--accent-color);
    color: var(--primary-text-color);
    width: fit-content;
    border-radius: 9999px;
    padding: 2px 10px;
    position: absolute;
    right: 20px;
    bottom: -10px;
    font-size: 11px;
    font-weight: bold;
}

.card .emoji-pill > div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2px;
}

.card .emoji-pill img {
    height: 15px;
    width: 15px;
}

.card .emoji-selector *{
    display: none;
    cursor: pointer;
    opacity: 0;
}
.card .emoji-selector.visible *{
    display: block;
    opacity: 1;
}

.card .emoji-selector .add{
    display: block !important;
    opacity: 1 !important;
}

.card .emoji-selector{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    transition: 0.3s ease-in-out;
}

.card .emoji-selector.visible{
    opacity: 1;
    border: 2px solid var(--second-text-color);
    border-radius: 9999px;
    padding: 5px 10px;
}

.card .content{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}


@media screen and (min-width: 800px){
    .card .content{
        flex-direction: row;
        gap: 40px;
    }
    .card .header h2{
        font-size: 40px;
    }

    .card .text{
        font-size: 22px;
        max-width: 40%;
    }

    .card{
        padding: 0 40px;
        margin: 40px;
        border: 4px solid var(--accent-color);
        border-radius: 20px;
    }

    .card .header,
    .card .footer{
        margin: 50px 0;
    }

    .card .header img{
        height: 40px;
        width: 40px;
    }

    .card .footer {
        height: 80px;
    }

    .card .footer span{
        font-size: 16px;
    }

    .card .emoji-pill{
        gap: 20px;
        padding: 4px 20px;
        right: 40px;
        bottom: -20px;
        font-size: 22px;
    }

    .card .emoji-pill > div {
        gap: 4px;
    }

    .card .emoji-pill img {
        height: 30px;
        width: 30px;
    }

    .card .emoji-selector{
        gap: 10px;
    }

    .card .emoji-selector.visible{
        border: 4px solid var(--second-text-color);
        padding: 10px 20px;
    }
    .card .emoji-selector img{
        height: 48px;
        width: 48px;
    }
}