.landing {
    text-align: center;
}

.landing h2{
    margin: 50px auto;
}

.landing .refresh-pill{
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 195px; /*to be fixed*/
    border-radius: 99999px;
    padding: 5px 10px;
    gap: 5px;
    background-color: var(--second-text-color);
    color: var(--main-bg-color);
    font-weight: 600;
    position: fixed;
    top: 65px;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
}

.landing .refresh-pill img{
    height: 20px;
    width: 20px;
}