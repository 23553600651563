.carousel-container {
    position: relative;
    margin: 20px auto;
    user-select: none;
}

.bremen .carousel-container {
    max-width: 300px;
}

.bremen .carousel{
    border-radius: 10px;
    border: solid 1px var(--second-text-color);
}

/* Style for the carousel images */
.carousel-container .images-carousel img {
    max-width: 100%;
    height: 200px;
    object-fit: cover;
}

/* Style for the dots */
.dots {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.dot {
    width: 5px;
    height: 5px;
    background-color: var(--second-text-color);
    border-radius: 9999px;
    margin: 0 5px;
    transition: width 0.5s ease-in-out;
    cursor: pointer;
}

.dot.active {
    width: 10px;
}

.carousel .control-dots{
    display: none;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1;
}

.full-size-image {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 90%;
    max-height: 90%;
    z-index: 2;
}

.prev-arrow,
.next-arrow,
.close-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 80px;
    cursor: pointer;
    color: var(--second-text-color);
    z-index: 10;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.prev-arrow {
    left: 20px;
}

.next-arrow {
    right: 20px;
}

.close-button {
    top: 20px;
    right: 20px;
    font-size: 30px;
}

@media screen and (min-width: 800px) {
    .prev-arrow,
    .next-arrow,
    .close-button {
        font-size: 80px;
    }

    .prev-arrow {
        left: 50px;
    }

    .next-arrow {
        right: 50px;
    }
    .carousel-container {
        max-width: 600px !important;
        margin: 40px auto;
    }
    .carousel-container .images-carousel img {
        height: 400px;
    }

    .bremen .carousel{
        border-radius: 20px;
        border: solid 2px var(--second-text-color);
    }

    .dots {
        margin-top: 20px;
    }

    .dot {
        width: 10px;
        height: 10px;
        margin: 0 10px;
    }

    .dot.active {
        width: 20px;
    }
}


