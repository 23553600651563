
@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(180deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes rotate2 {
    0% {
        transform: rotate(0deg);
        border-top-color: var(--second-bg-color);
    }
    50% {
        transform: rotate(180deg);
        border-top-color: var(--second-bg-color);
    }
    100% {
        transform: rotate(360deg);
        border-top-color: var(--second-text-color);
    }
}

.loader {
    position: relative;
    margin: 25px auto; /*75px*/
    width: 50px; /*150px*/
    height: 50px; /*150px*/
    display: block;
    overflow: hidden;
}

.loader div {
    height: 100%;
}

/* loader 1 */
.loader1, .loader1 div {
    border-radius: 50%;
    padding: 2px; /*8px*/
    border: 1px solid transparent; /*2px*/
    animation: rotate linear 3.5s infinite;
    border-top-color: var(--second-text-color);
    border-bottom-color: var(--second-bg-color);
}

/* loader 2 */
.loader2, .loader2 div {
    border-radius: 50%;
    padding: 8px;
    border: 2px solid transparent;
    animation: rotate linear 3.5s infinite;
    border-top-color: var(--second-bg-color);
    border-left-color: var(--second-text-color);
    border-right-color: var(--second-text-color);
}

/* loader 3 */
.loader3, .loader3 div {
    border-radius: 50%;
    padding: 8px;
    border: 2px solid transparent;
    animation: rotate linear 3.5s infinite;
    border-top-color: var(--second-text-color);
    border-left-color: var(--second-bg-color);
    animation-timing-function: cubic-bezier(.55, .38, .21, .88);
    animation-duration: 3s;
}

/* loader 4 */
.loader4, .loader4 div {
    border-radius: 50%;
    padding: 4px;
    animation: rotate2 4s infinite linear;
    border-top-color: var(--second-text-color);
}

div:hover {
    animation-play-state: paused;
}

.loader, .loader * {
    will-change: transform;
}
