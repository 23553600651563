.post-page {
    margin: 20px;
    text-align: justify;
}

.post-page p {
    color: var(--second-text-color);
}

.post-page .post-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 30px 0 15px 0;
}

.post-page .post-header > div {
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: italic;
    gap: 5px;
    cursor: pointer;
    min-width: fit-content;
    padding-left: 5px;
}

.post-page .post-header > div img {
    width: 20px;
    height: 20px;
}

.post-page .emoji-set {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.post-page .emoji-set > div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.post-page .footer {
    display: block;
    text-align: center;
    font-style: italic;
    margin-top: 20px;
    font-size: 10px;
}

.post-page .footer .bar {
    display: block;
    height: 1px;
    width: 100%;
    margin: 0 auto;
    background-color: var(--second-text-color);
}

.post-page .footer span {
    display: block;
    margin-top: 10px;
}


@media screen and (min-width: 800px){
    .post-page {
        width: 600px;
        margin: 40px auto;
    }
}