.post-btn{
    color: var(--second-text-color);
    border-radius: 5px;
    border: 2px solid var(--second-text-color);
    background-color: var(--main-bg-color);
    cursor: pointer;
    font-weight: bold;
    padding: 5px;
    width: fit-content;
}

.post-btn.over{
    position: absolute;
    bottom: 10%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
}

.post-btn:hover{
    color: var(--second-bg-color);
    border-radius: 5px;
    background-color: var(--second-text-color);
    border: 2px solid var(--second-bg-color);
    cursor: pointer;
}

.album-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10px;
    border-radius: 10px;
    border: 2px solid var(--second-text-color);
    overflow: hidden;
    cursor: pointer;
}

.gallery{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: unset;
    justify-content: center;
}

.gallery .plus-btn-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
}