.dashboard{
    padding: 20px;
}

.dashboard h2 {
    margin: 20px;
}

.dashboard form > div {
    border: 1px solid var(--second-text-color);
    padding: 10px;
    border-radius: 10px;
    margin: 15px;
    display: flex;
    gap: 5px;
}

.dashboard textarea{
    height: 100px;
}

.dashboard form > div:last-child{
    justify-content: center;
}