:root {
  --second-bg-color: #1E555C;
  --main-bg-color: #00292F;
  --second-text-color: #CFB9A5;
  --primary-text-color: white;
  --accent-color: #CFB9A5
}

body.stanford {
  --main-bg-color: #2E2D29;
  --second-bg-color: #175E54;
  --primary-text-color: #EAEAEA;
  --second-text-color: #ACACAC;
  --accent-color: #8C1515;
  font-family: "Source Sans 3", sans-serif;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;


  color: var(--primary-text-color);
  background: var(--main-bg-color);
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Reset*/

/* Box-sizing border-box for all elements */
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* Remove default list styles */
ul, ol {
  list-style: none;
}

/* Remove default margin and padding for headings */
h1, h2, h3, h4, h5, h6,
p, figure, blockquote,
dl, dd, dt {
  margin: 0;
  padding: 0;
}

/* Remove hyperlink styles */
a {
  text-decoration: none;
  color: inherit;
}

/* Remove default button styles */
button, input, select, textarea {
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  color: inherit;
  font: inherit;
}

/* Remove outline on active elements (e.g., links when clicked) */
:focus {
  outline: none;
}

/* Reset form elements to their default styles */
input, select, textarea {
  appearance: none;
  border-radius: 0;
}

/* Reset image borders */
img {
  border: none;
  max-width: 100%;
  height: auto;
}



